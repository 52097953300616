import React from 'react'
import {
  B2BRegistrationDesktopButton,
  B2BRegistrationMobileButton,
  ButtonGroup,
  ButtonGroupWithB2B,
  ChoosePsychologistButton,
  LoginButton,
  Title,
  VideoCallIcon,
  Wrap
} from './index.styles'
import { selectDeviceSizeIsMobile } from '../../../../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

export const QuestionStep = ({ goNext }) => {
  const isMobile = useSelector(selectDeviceSizeIsMobile)
  return (
    <Wrap>
      <VideoCallIcon />
      <Title bold>У вас уже есть аккаунт в YouTalk?</Title>
      <ButtonGroupWithB2B>
        <ButtonGroup>
          <ChoosePsychologistButton
            onClick={goNext}
            size={isMobile ? 'medium' : 'small'}
          >
            Подобрать психолога
          </ChoosePsychologistButton>
          <LoginButton
            href="/account/login"
            size={isMobile ? 'medium' : 'small'}
            type="externalLink"
          >
            У меня есть аккаунт
          </LoginButton>
        </ButtonGroup>
        <B2BRegistrationDesktopButton
          href="/b2b_signup"
          size={isMobile ? 'medium' : 'small'}
          type="externalLink"
        >
          Зарегистрироваться как B2B клиент
        </B2BRegistrationDesktopButton>
      </ButtonGroupWithB2B>
      <B2BRegistrationMobileButton
        href="/b2b_signup"
        size={isMobile ? 'medium' : 'small'}
        type="externalLink"
      >
        Зарегистрироваться как B2B клиент
      </B2BRegistrationMobileButton>
    </Wrap>
  )
}
