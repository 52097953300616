import styled from 'styled-components'
import { Button } from '../../../../atoms/Button'
import { Text } from '../../../../atoms/Text'
import { ReactComponent as VideoCallSVG } from './img/video-call.svg'
import { size } from '../../../../constants'

export const VideoCallIcon = styled(VideoCallSVG)`
  display: none;

  @media (max-width: ${size.sm}) {
    display: block;
  }
`

export const Title = styled(Text.Large)`
  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }
`

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 8px;

  @media (max-width: ${size.sm}) {
    padding: 0;
    gap: 32px;
  }
`

export const ButtonGroupWithB2B = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 56px;

  @media (max-width: ${size.sm}) {
    width: 100%;
    gap: 8px;
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media (max-width: ${size.sm}) {
    width: 100%;
    gap: 8px;
  }
`

export const LoginButton = styled(Button.Grey)`
  width: 100%;

  @media (max-width: ${size.sm}) {
    width: 100%;
  }
`

export const ChoosePsychologistButton = styled(Button.NewPrimary)`
  width: 100%;

  @media (max-width: ${size.sm}) {
    width: 100%;
  }
`

export const B2BRegistrationDesktopButton = styled(Button.Grey)`
  background-color: white;
  border-color: white;
  width: 100%;

  @media (max-width: ${size.sm}) {
    display: none;
  }
`

export const B2BRegistrationMobileButton = styled(Button.Grey)`
  display: none;

  @media (max-width: ${size.sm}) {
    display: flex;
    position: absolute;
    bottom: 80px;
    background-color: white;
    border-color: white;
    width: 100%;
  }
`
