import Popup from 'reactjs-popup'
import React from 'react'
import styled, { css } from 'styled-components'
import { ArrowSlim } from './ArrowSlim'
import { Link } from 'gatsby'
import { useNavData } from './useNavData'

const linkStyles = css`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: none;
  color: #333333;
  display: flex;
  white-space: nowrap;
  &:focus {
    outline: none;
  }
`

const linkStylesHover = css`
  &:hover {
    color: #03b2a5;
    text-decoration: none;
  }
`

const linkStylesHoverBg = css`
  &:hover {
    background-color: #f6f6f6;
  }
`

const MenuTopLink = styled(Link)`
  ${linkStyles}
  ${linkStylesHover}
`

const MenuTopButton = styled.div`
  ${linkStyles}
  ${linkStylesHover}
  cursor: pointer;
  align-items: center;
  gap: 4px;
`

const MenuDropdown = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 8px 1px;
  border: 1px solid rgb(222, 222, 222);
  border-radius: 8px;
  background: rgb(255, 255, 255);
  transform: translate(0px, 8px);
`
const MenuLink = styled(Link)`
  ${linkStyles}
  ${linkStylesHover}
  ${linkStylesHoverBg}
  padding: 8px 16px;
`

const MenuNested = styled.div`
  ${linkStyles}
  ${linkStylesHover}
  ${linkStylesHoverBg}
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
`
const Main = styled.div`
  display: flex;
  flexdirection: row;
  gap: 8px;
`

export const TopMenu = () => {
  const nav = useNavData()
  return (
    <Main>
      {nav.map((item, i) =>
        item.nested ? (
          <Popup
            key={i}
            arrow
            nested
            on="hover"
            position="bottom left"
            trigger={(isOpen) => (
              <MenuTopButton>
                {item.name}
                {isOpen ? <ArrowSlim /> : <ArrowSlim rotate={-180} />}
              </MenuTopButton>
            )}
          >
            <MenuDropdown>
              {item.nested.map((nestedItem, i) =>
                nestedItem.children ? (
                  <Popup
                    key={i}
                    nested
                    on="hover"
                    position="right top"
                    trigger={
                      <MenuNested>
                        {nestedItem.name}
                        <ArrowSlim rotate={90} />
                      </MenuNested>
                    }
                  >
                    <MenuDropdown>
                      {nestedItem.children.map((doubleNestedItem, i) => (
                        <MenuLink key={i} to={doubleNestedItem.path}>
                          {doubleNestedItem.name}
                        </MenuLink>
                      ))}
                    </MenuDropdown>
                  </Popup>
                ) : (
                  <MenuLink key={i} to={nestedItem.path}>
                    {nestedItem.name}
                  </MenuLink>
                )
              )}
            </MenuDropdown>
          </Popup>
        ) : (
          <MenuTopLink key={i} to={item.path}>
            {item.name}
          </MenuTopLink>
        )
      )}
    </Main>
  )
}
