import React from 'react'
import { Link } from 'gatsby'

const PickUp = ({ className = '', onClick }) => (
  <Link className={`${className} button`} onClick={onClick} to="/wizard/">
    Найти&nbsp;психолога
  </Link>
)

export default PickUp
